
//Import scss, css, js files, it can be either dist or src

import './vendor/fontawesome/css/all.scss'
import './vendor/jquery-ui/jquery-ui-1.10.4.custom.css'
import './vendor/swiper/swiper-bundle.min.css' // swiper
import './vendor/odometer/themes/odometer-theme-default.css' // odometer
import './vendor/magnific-popup/dist/magnific-popup.css' // magnific-popup
import './vendor/spotlight-master/dist/css/spotlight.min.css'

import './vendor/iframe-resizer/iframeResizer.min'
import './vendor/jquery-ui/jquery-ui-1.10.4.custom'
import './vendor/swiper/swiper-bundle.min' // swiper
import './vendor/scrollmagic/ScrollMagic' // scrollmagic
import './vendor/scrollmagic/plugins/debug.addIndicators' // scrollmagic addIndicators
import './vendor/odometer/odometer' // odometer
import './vendor/magnific-popup/dist/jquery.magnific-popup.min' // magnific-popup
import './vendor/spotlight-master/dist/js/spotlight.min.js'
import './vendor/jquery-ui-touch-punch-master/jquery.ui.touch-punch.min'

